import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, dispatch } from "../store";
import { ScanLogState } from "src/@types/scanlog";
import { DataLog, SavedLog } from "src/@types/log";
import apiClient from "../../utils/axios";

const initialDataLog = {
  saved_log: {
    total: 0,
    page: 0,
    pages: 0,
    limit: 0,
    data: { headers: [], rows: [] },
  },
};
const initialState: ScanLogState = {
  isLoading: false,
  error: "",
  datalog: initialDataLog,
};

const slice = createSlice({
  name: "scanlog",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LOGS
    getDataLogsSuccess(state, action) {
      state.isLoading = false;
      state.datalog = action.payload;
    },
  },
});

function escapeRegExp(string: any) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function replaceAll(str: any, find: any, replace: any) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

// Reducer
export default slice.reducer;
export function getDataLogs(
  pageNumber: String,
  limit: String,
  template_id: String,
  fromDate: String,
  toDate: String,
  searchText: String,
  isOutputCompleted: boolean
): AppThunk {
  // const dateFormatAPI = 'YYYY-MM-DD';
  // const defaultToDate = moment().format(dateFormatAPI);
  const formattedFromDate = replaceAll(fromDate, "/", "-");
  const formattedToDate = replaceAll(toDate, "/", "-");

  return async (_, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      if (template_id == null || template_id === "") {
        dispatch(slice.actions.getDataLogsSuccess(initialDataLog));
        return;
      }
      const connectionId =
        getState().connections.selectedConnection?.company_id;
      const url = "/qr-scan-results/view/" + connectionId;
      const params = {
        page: pageNumber,
        page_limit: limit,
        template_id,
        start_date: formattedFromDate,
        end_date: formattedToDate,
        search_term: searchText,
        is_downloaded: isOutputCompleted,
      };

      const result = await apiClient.get<DataLog>(url, { params });

      const response = result;
      dispatch(slice.actions.getDataLogsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function initDataLogs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    // setTimeout(function () {
    dispatch(slice.actions.getDataLogsSuccess(initialDataLog));
    // }, 10);
  };
}

export function updateDataLogs(saveLogData: SavedLog) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // setTimeout(function () {
    dispatch(slice.actions.getDataLogsSuccess({ saved_log: saveLogData }));
    // }, 10);
  };
}
